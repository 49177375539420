import React, { useEffect, useMemo, useState } from "react";
import { Table } from "reactstrap";
import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function getLogoUrl(storage) {
  if (storage && storage.storageProvider === "url") {
    return storage.storageUri;
  }

  return "";
}

function useQuery() {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Doctors = () => {
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);

  let query = useQuery();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const resp = await Api.getDoctors(query);
        setDoctors(resp.data || []);
      } catch (error) {
        toast.error("Error " + error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().catch(() => {});
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="row mb-3">
            <div className="col-md d-flex justify-content-between align-items-center">
              <h1>Doctors</h1>
              {/* <Link to="/doctors/add">Add</Link> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {doctors.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <img
                            src={getLogoUrl(item.profilePicture)}
                            style={{ width: 36, height: 36 }}
                          />
                        </td>
                        <td>{[item.firstName, item.lastName].join(" ")}</td>
                        <td>{item.email}</td>
                        <td align="right">
                          {item.attachedUserId ? (
                            <Link
                              className="btn btn-secondary mr-3"
                              to={`/users/${item.attachedUserId}`}
                            >
                              User
                            </Link>
                          ) : (
                            <Link
                              className="btn btn-secondary mr-3"
                              to={`/users/add/?accountType=doctor&entityId=${item._id}`}
                            >
                              Create User
                            </Link>
                          )}

                          <Link
                            className="btn btn-secondary"
                            to={`/patients/?doctorId=${item._id}`}
                          >
                            Patients
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Doctors;
