import React, { useEffect, useMemo, useState } from "react";
import { Table } from "reactstrap";
import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";

function getLogoUrl(storage) {
  if (storage && storage.storageProvider === "url") {
    return storage.storageUri;
  }

  return "";
}

function useQuery() {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
}

const TechnicianAssignProject = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [technicians, setTechnicians] = useState([]);
  const [project, setProject] = useState({});
  const match = useRouteMatch();

  let query = useQuery();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        let resp;
        resp = await Api.getProjectById(match.params.projectId);
        if (!resp.data) {
          throw Error("Uknown project");
        }
        setProject(resp.data);
        resp = await Api.getTechnicians(query);
        setTechnicians(resp.data || []);
      } catch (error) {
        toast.error("Error " + error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().catch(() => {});
  }, [query]);

  const assignTo = async (technicianId) => {
    try {
      setLoading(true);
      let resp;
      resp = await Api.techniciansAssignProject(
        technicianId,
        match.params.projectId
      );
      toast.success("Assigned");
      history.push(`/technicians/${technicianId}`);
    } catch (error) {
      toast.error("Error " + error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="row mb-3">
            <div className="col-md d-flex justify-content-between align-items-center">
              <h1>Assign Project: {project.name}</h1>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md d-flex justify-content-between align-items-center">
              <h2>Technicians</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Photo</th>
                    <th>Name</th>
                    <th>Organization</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {technicians.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <img
                            src={getLogoUrl(item.profilePicture)}
                            style={{ width: 36, height: 36 }}
                          />
                        </td>
                        <td>{[item.firstName, item.lastName].join(" ")}</td>
                        <td>{item.organization}</td>
                        <td>{item.email}</td>
                        <td align="right">
                          <button
                            className="btn btn-secondary"
                            to={`/technicians/${item._id}`}
                            onClick={() => assignTo(item._id)}
                          >
                            Assign
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TechnicianAssignProject;
