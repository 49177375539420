import React from "react";
import { withRouter, Link, NavLink } from "react-router-dom";

const ProtectedPage = (props) => {
  const Component = props.component;

  return (
    <div className="zz-d-flex-column-1">
      <nav className="zz-navbar">
        <Link className="zz-navbar__logo text-white" to="/">
          ZZ
        </Link>
        <div className="zz-navbar__items">
          {[
            ["/users/", "Users"],
            ["/clinics/", "Clinics"],
            ["/doctors/", "Doctors"],
            ["/patients/", "Patients"],
            ["/treatments/", "Treatments"],
            ["/technicians/", "Technicians"],
          ].map((i) => (
            <NavLink
              key={i[0]}
              to={`${i[0]}`}
              activeClassName="zz-navbar__item--active"
            >
              {i[1]}
            </NavLink>
          ))}
        </div>
      </nav>

      <div className="container mt-5 zz-d-flex-column-1">
        <Component />
      </div>
    </div>
  );
};

export default withRouter(ProtectedPage);
