import React, { useEffect, useMemo, useState } from "react";
import { Table } from "reactstrap";
import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

function getLogoUrl(storage) {
  if (storage && storage.storageProvider === "url") {
    return storage.storageUri;
  }

  return "";
}

function useQuery() {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
}

const Projects = () => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  let query = useQuery();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const resp = await Api.getProjects(query);
        setProjects(resp.data || []);
      } catch (error) {
        toast.error("Error " + error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().catch(() => {});
  }, [query]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="row mb-3">
            <div className="col-md d-flex justify-content-between align-items-center">
              <h1>Treatments</h1>
              {/* <Link to="/projects/add">Add</Link> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {projects.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.name}</td>
                        <td>{item.description}</td>
                        <td align="right">
                          {item.assignedTechnicians &&
                          item.assignedTechnicians.length > 0 ? (
                            <Link
                              className="btn btn-secondary mr-3"
                              to={`/technicians/?projectId=${item._id}`}
                            >
                              Technicians
                            </Link>
                          ) : null}
                          <Link
                            className="btn btn-secondary mr-3"
                            to={`/technicians/assignTreatment/${item._id}`}
                          >
                            Assign Tech.
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Projects;
