import React from "react";
import * as Api from "../services/Api";
import { useHistory } from "react-router-dom";
import { UiStore } from "../stores/UiStore";

const Logout = () => {
  const history = useHistory();

  const doLogout = () => {
    Api.setAccessToken(null);

    UiStore.accessToken = null;
    UiStore.user = null;
    history.push("/");
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div>
            <h1 className="my-5 text-center">Logout from the system</h1>
            <div className="text-center">
              <button className="btn btn-primary" onClick={() => doLogout()}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Logout;
