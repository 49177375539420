import React from "react";
import PropTypes from "prop-types";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";

import { OFFLoader } from "../services/OFFLoader";
import { PLYLoader } from "../services/PLYLoader";
import { getFileExt } from "../services/utils";

const TARGET_RADIUS = 40;

const SUPPORTED_EXTS = ["stl", "obj", "ply", "off"];

// Assumption. could be any theoretically. Otherwise we neeed to parse obj,
// find mtl/mat and then parse again
const SUPPORTED_MTL_EXTS = ["mat", "mtl"];

class ThreeJsViewer extends React.Component {
  constructor(props) {
    super(props);

    this.rootRef = React.createRef();

    this.state = { loading: true, loadingProgress: 0 };

    this.renderRequested = false;
    this.camera = null;
    this.cameraTarget = null;
    this.scene = null;
    this.renderer = null;

    this.fileUrl = null;
    this.fileType = null;
    this.mtlFiles = null;

    if (this.props.fileUrl) {
      this.fileUrl = this.props.fileUrl;
      this.fileType = this.props.fileType || this.getFileTypeFromUrl(this.fileUrl);
    } else {
      this.extractInfoFromFiles();
    }

    console.log("File Info", this.fileUrl, this.fileType, this.mtlFiles);
  }

  getFileTypeFromUrl(fileUrl) {
    const url = new URL(fileUrl);
    const p = url.pathname;

    return getFileExt(p);
  }

  extractInfoFromFiles() {
    const files = this.props.files || {};

    let mainFile = null;
    let mtlFiles = [];

    files.forEach((f) => {
      if (f.name.includes("/")) {
        // We only need root files
        return;
      }

      const ext = getFileExt(f.name).toLowerCase();

      if (!mainFile && SUPPORTED_EXTS.includes(ext)) {
        mainFile = f;
      }

      if (SUPPORTED_MTL_EXTS.includes(ext)) {
        mtlFiles.push(f.name);
      }
    });

    this.fileUrl = this.props.baseUrl + mainFile.name;
    this.fileType = getFileExt(mainFile.name);
    this.mtlFiles = mtlFiles;
  }

  initThree() {
    let container = this.rootRef.current;

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    // this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.setClearColor(0x333333, 1);

    this.renderer.shadowMap.enabled = true;

    container.appendChild(this.renderer.domElement);

    // SCENE
    this.scene = new THREE.Scene();
    this.scene.rotation.x = -Math.PI / 2;
    this.scene.rotation.z = Math.PI;
    // this.scene.background = new THREE.Color(0x72645b);

    // CAMERA
    this.camera = new THREE.PerspectiveCamera(
      this.props.defaultFov || 30,
      container.clientWidth / container.clientHeight,
      0.01,
      1000
    );

    this.camera.position.x = 150;
    this.camera.position.y = 150;
    this.camera.position.z = 150;

    // LIGHTS

    this.ambientLight = new THREE.AmbientLight(0x101010);
    this.scene.add(this.ambientLight);

    const light1 = new THREE.DirectionalLight(0xffffff, 1);
    light1.position.set(200, 200, 200).normalize();
    this.scene.add(light1);

    const light2 = new THREE.DirectionalLight(0xffffff, 1);
    light2.position.set(-200, -200, 200).normalize();
    this.scene.add(light2);

    const light3 = new THREE.DirectionalLight(0xffffff, 1);
    light3.position.set(0, 0, -200).normalize();
    this.scene.add(light3);

    // let helper = new THREE.DirectionalLightHelper(light1, 5);
    // this.scene.add(helper);

    // helper = new THREE.DirectionalLightHelper(light2, 5);
    // this.scene.add(helper);

    // OBJECTS
    // const geometry = new THREE.BoxGeometry(1, 1, 1);
    // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    // const cube = new THREE.Mesh(geometry, material);
    // this.scene.add(cube);

    // We need to convert path extenstion to lower case
    this.loadingManagerLC = new THREE.LoadingManager();
    this.loadingManagerLC.setURLModifier((url) =>
      url.replace(/\.([^.]+)$/, (_m, m1) => `.${m1.toLowerCase()}`)
    );

    if (this.fileUrl) {
      switch (this.fileType) {
        case "stl":
          this.loadStl();
          break;
        case "ply":
          this.loadPly();
          break;
        case "off":
          this.loadOff();
          break;
        case "obj":
          this.loadObj();
          break;
        default:
          break;
      }
    }

    // HELPERS

    const controls = new OrbitControls(this.camera, this.renderer.domElement);
    controls.target.set(0, 0, 0);
    controls.update();
    controls.addEventListener("change", this.requestRenderIfNotRequested);

    const axesHelper = new THREE.AxesHelper(15);
    this.scene.add(axesHelper);

    // stats = new Stats();
    // container.appendChild(stats.dom);

    this.animate();

    window.addEventListener("resize", this.onWindowResize, false);
  }

  componentDidMount() {
    this.initThree();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize = () => {
    const container = this.rootRef.current;

    this.camera.aspect = container.clientWidth / container.clientHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(container.clientWidth, container.clientHeight);
    this.requestRenderIfNotRequested();
  };

  renderScene = () => {
    this.renderRequested = false;

    this.renderer.render(this.scene, this.camera);
  };

  requestRenderIfNotRequested = () => {
    console.log('camera', this.camera.position);
    
    if (!this.renderRequested) {
      this.renderRequested = true;
      requestAnimationFrame(this.renderScene);
    }
  };

  animate = () => {
    // requestAnimationFrame(this.animate);
    this.requestRenderIfNotRequested();
  };

  onModelLoaded = () => {
    this.setState({ loading: false });
    this.requestRenderIfNotRequested();
    this.props.onModelLoaded && this.props.onModelLoaded();
  }

  loadStl() {
    const loader = new STLLoader();
    loader.load(
      this.fileUrl,
      (geometry) => {
        const material = new THREE.MeshPhongMaterial({
          // color: 0xff5533,
          color: 0xccf0ff,

          specular: 0x111111,
          // specular: 0x000000,
          shininess: 200,
        });
        const mesh = new THREE.Mesh(geometry, material);

        geometry.computeBoundingSphere();

        console.log("bs", geometry.boundingSphere);

        let center = geometry.boundingSphere.center;
        let targetRadius = TARGET_RADIUS;
        let scale = targetRadius / geometry.boundingSphere.radius;

        mesh.scale.set(scale, scale, scale);
        mesh.position.set(
          -center.x * scale,
          -center.y * scale,
          -center.z * scale
        );

        // mesh.castShadow = true;
        // mesh.receiveShadow = true;

        this.scene.add(mesh);
        this.onModelLoaded();
      },
      (xhr) =>
        this.setState({
          loadingProgress: `${Math.floor((xhr.loaded / xhr.total) * 100)}`,
        })
    );
  }

  loadOff() {
    const loader = new OFFLoader();
    loader.load(
      this.fileUrl,
      (geometry) => {
        geometry.computeVertexNormals();

        const material = new THREE.MeshPhongMaterial({
          // color: 0xff5533,
          color: 0xccf0ff,

          specular: 0x111111,
          // specular: 0x000000,
          shininess: 200,
        });
        const mesh = new THREE.Mesh(geometry, material);

        geometry.computeBoundingSphere();

        console.log("bs", geometry.boundingSphere);

        let center = geometry.boundingSphere.center;
        let targetRadius = TARGET_RADIUS;
        let scale = targetRadius / geometry.boundingSphere.radius;

        mesh.scale.set(scale, scale, scale);
        mesh.position.set(
          -center.x * scale,
          -center.y * scale,
          -center.z * scale
        );

        // mesh.castShadow = true;
        // mesh.receiveShadow = true;

        this.scene.add(mesh);
        this.onModelLoaded();
      },
      (xhr) =>
        this.setState({
          loadingProgress: `${Math.floor((xhr.loaded / xhr.total) * 100)}`,
        })
    );
  }

  loadPly() {
    const loader = new PLYLoader();
    loader.load(
      this.fileUrl,
      (geometry) => {
        console.log("ply header", geometry._plyHeader);
        let textureFile = null;

        // Get texture file
        if (geometry._plyHeader?.comments) {
          for (let c of geometry._plyHeader.comments) {
            const pattern = /TextureFile\s+(.+)$/;
            const m = c.match(pattern);
            if (m) {
              textureFile = m[1];
              break;
            }
          }
        }

        console.log("TextureFile", textureFile);

        const _createMesh = (material) => {
          geometry.computeVertexNormals();

          const mesh = new THREE.Mesh(geometry, material);

          geometry.computeBoundingSphere();

          console.log("bs", geometry.boundingSphere);

          let center = geometry.boundingSphere.center;
          let targetRadius = TARGET_RADIUS;
          let scale = targetRadius / geometry.boundingSphere.radius;

          mesh.scale.set(scale, scale, scale);
          mesh.position.set(
            -center.x * scale,
            -center.y * scale,
            -center.z * scale
          );

          // mesh.castShadow = true;
          // mesh.receiveShadow = true;
          this.scene.add(mesh);
        };

        if (textureFile) {
          new THREE.TextureLoader()
            .setPath(this.props.baseUrl)
            .load(textureFile, (texture) => {
              let material = new THREE.MeshStandardMaterial({
                map: texture,
              });
              _createMesh(material);
              this.onModelLoaded();
            });
        } else {
          const material = new THREE.MeshPhongMaterial({
            color: 0xccf0ff,
            specular: 0x111111,
            shininess: 200,
          });
          _createMesh(material);
          this.onModelLoaded();
        }
      },
      (xhr) =>
        this.setState({
          loadingProgress: `${Math.floor((xhr.loaded / xhr.total) * 100)}`,
        })
    );
  }

  loadObj() {
    const _loadObj = (materials) => {
      const loader = new OBJLoader();
      loader.setMaterials(materials);
      loader.load(
        this.fileUrl,
        (object) => {
          const b3 = new THREE.Box3().setFromObject(object);
          const s = new THREE.Sphere();
          b3.getBoundingSphere(s);

          let center = s.center;
          let targetRadius = TARGET_RADIUS;
          let scale = targetRadius / s.radius;

          object.scale.set(scale, scale, scale);
          object.position.set(
            -center.x * scale,
            -center.y * scale,
            -center.z * scale
          );

          this.scene.add(object);
          this.setState({ loading: false });
          this.requestRenderIfNotRequested();
        },
        (xhr) =>
          this.setState({
            loadingProgress: `${Math.floor((xhr.loaded / xhr.total) * 100)}`,
          })
      );
    };

    if (this.mtlFiles && this.mtlFiles.length > 0) {
      new MTLLoader()
        .setPath(this.props.baseUrl)
        .load(this.mtlFiles[0], function (materials) {
          materials.preload();

          _loadObj(materials);
        });
    } else {
      _loadObj();
    }
  }

  render() {
    return (
      <div className="zz-d-flex-column-1" style={{ position: "relative" }}>
        <div
          ref={this.rootRef}
          style={{ flex: 1, flexDirection: "column" }}
        ></div>
        {this.state.loading && (
          <div id="loading-screen">
            <div id="loader"></div>
            <div id="loader-progress">{this.state.loadingProgress}%</div>
          </div>
        )}
      </div>
    );
  }
}

ThreeJsViewer.propTypes = {
  fileUrl: PropTypes.string,
  baseUrl: PropTypes.string,
  files: PropTypes.array,
};

export default ThreeJsViewer;
