import React, { useEffect, useMemo, useReducer, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";

import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

function useQuery() {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
}

const EditTechnician = () => {
  const [__version, forceUpdate] = useReducer(x => x + 1, 0);

  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [technician, setTechnician] = useState({});
  const query = useQuery();

  const technicianId = match.params.technicianId;

  useEffect(() => {
    (async () => {
      setLoading(true);
      let t = null;
      if (technicianId) {
        t = (await Api.getTechnicianById(technicianId)).data;
        if (t.projects) {
          for (let i = 0; i < t.projects.length; i++) {
            const p = t.projects[i];
            const resp = await Api.getProjectById(p.projectId);
            p.data = resp.data;
          }
        }
      } else {
        t = {};
      }

      setTechnician(t);

      setLoading(false);
    })();
  }, [query, technicianId, __version]);

  const removeProject = async (projectId) => {
    try {
      setLoading(true);
      let resp;
      resp = await Api.techniciansRemoveProject(technician._id, projectId);
      toast.success("Removed");
      // history.push(`/technicians/${technicianId}`);
      forceUpdate();
    } catch (error) {
      toast.error("Error " + error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="row mb-3">
            <div className="col-md">
              <h1>Technician - Edit</h1>
            </div>
          </div>
          <Formik
            initialValues={technician}
            validate={(values) => {
              const errors = {};

              if (!values.email || !values.email.trim()) {
                errors.email = "Email is required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              (async function () {
                try {
                  if (technicianId) {
                    await Api.updateTechnician(technicianId, values);
                    toast.success("Saved");
                  } else {
                    const u = (await Api.addTechnician(values)).data;
                    history.push(`/technicians/${u._id}`);
                  }
                } catch (error) {
                  console.log("error", error);
                  toast.error("Error " + error);
                } finally {
                  setSubmitting(false);
                }
              })();
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   setSubmitting(false);
              // }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                {[
                  ["email", "Email"],
                  ["firstName", "First Name"],
                  ["lastName", "Last Name"],
                  ["organization", "Organization"],
                ].map((i) => (
                  <div className="row">
                    <div className="col-md">
                      <div className="form-group">
                        <label>{i[1]}</label>
                        <input
                          type="text"
                          className={
                            "form-control" + (errors[i[0]] ? " is-invalid" : "")
                          }
                          name={i[0]}
                          value={values[i[0]] || ""}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></input>
                        <div className="invalid-feedback">{errors[i[0]]}</div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="row">
                  <div className="col-md">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <div className="row mt-5">
            <div className="col-md">
              <h2>Assigned Projects</h2>
            </div>
          </div>
          {(technician.projects || []).map((p) => (
            <div className="row">
              <div className="col-md">
                {p?.data?.name} ({p.projectId})
              </div>
              <div className="col-md">
                <button
                  className="btn btn-primary"
                  onClick={() => removeProject(p.projectId)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default EditTechnician;
