import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { UiStore } from "./stores/UiStore";

import * as Api from "./services/Api";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { Loading } from "./components/Loading";
import Logout from "./components/Logout";
import Users from "./components/Users";
import EditUser from "./components/EditUser";
import Clinics from "./components/Clinics";
import Doctors from "./components/Doctors";
import Patients from "./components/Patients";
import Projects from "./components/Projects";
import Technicians from "./components/Technicians";
import TechnicianAssignProject from "./components/TechnicianAssignProject";
import FileViewer from "./components/FileViewer";

import ProtectedPage from "./pages/ProtectedPage";
import EditTechnician from "./components/EditTechnician";

function NotFound() {
  return <h1>Unknown Route</h1>;
}

function ProtectedRoute({ children, ...rest }) {
  if (UiStore.user) {
    return <Route {...rest}>{children}</Route>;
  }

  const { exact, path } = rest;
  return (
    <Route
      {...{ exact, path }}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: location },
          }}
        />
      )}
    />
  );
}

function AppWithRouter() {
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  useEffect(() => {
    (async function () {
      try {
        let res = await Api.getUserProfile();
        UiStore.user = res.data;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // history.push("/login", {
          //   from: {
          //     pathname: window.location.pathname,
          //     search: window.location.search,
          //   },
          // });
        } else {
          // toast.error("Error: " + error, {
          //   position: "bottom-center",
          // });
        }
      } finally {
        setLoading(false);
      }
    })();
    return () => {};
  }, [history]);

  return (
    <>
      <ToastContainer autoClose={2000} />
      {loading && <Loading />}
      {!loading && (
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/fileviewer" exact>
            <FileViewer />
          </Route>
          <ProtectedRoute path="/users" exact>
            <ProtectedPage component={Users} />
          </ProtectedRoute>
          <ProtectedRoute path="/users/add" exact>
            <ProtectedPage component={EditUser} />
          </ProtectedRoute>
          <ProtectedRoute path="/users/:userId" exact>
            <ProtectedPage component={EditUser} />
          </ProtectedRoute>
          <ProtectedRoute path="/clinics" exact>
            <ProtectedPage component={Clinics} />
          </ProtectedRoute>
          <ProtectedRoute path="/doctors" exact>
            <ProtectedPage component={Doctors} />
          </ProtectedRoute>
          <ProtectedRoute path="/patients" exact>
            <ProtectedPage component={Patients} />
          </ProtectedRoute>
          <ProtectedRoute path="/treatments" exact>
            <ProtectedPage component={Projects} />
          </ProtectedRoute>
          <ProtectedRoute path="/technicians" exact>
            <ProtectedPage component={Technicians} />
          </ProtectedRoute>
          <ProtectedRoute path="/technicians/add" exact>
            <ProtectedPage component={EditTechnician} />
          </ProtectedRoute>
          <ProtectedRoute path="/technicians/:technicianId" exact>
            <ProtectedPage component={EditTechnician} />
          </ProtectedRoute>
          <ProtectedRoute path="/technicians/assignTreatment/:projectId" exact>
            <ProtectedPage component={TechnicianAssignProject} />
          </ProtectedRoute>
          <ProtectedRoute path="/logout" exact>
            <ProtectedPage component={Logout} />
          </ProtectedRoute>
          <ProtectedRoute path="/" exact>
            <Home />
          </ProtectedRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      )}
    </>
  );
}

function App() {
  return (
    <Router>
      <AppWithRouter />
    </Router>
  );
}

export default App;
