import React from "react";

import { Link, NavLink } from "react-router-dom";
import { UiStore } from "../stores/UiStore";

const Home = () => {
  return (
    <div className="zz-flex-1">
      <nav className="zz-navbar">
        <Link className="zz-navbar__logo text-white" to="/">
          ZZ
        </Link>
        <div className="zz-navbar__items">
          <NavLink activeClassName="zz-navbar__item--active" to={`/logout`}>
            Logout
          </NavLink>
        </div>

      </nav>

      <div className="container">
        <div className="row justify-content-center">
          <div>
            <h1 className="mt-5 text-center">Modules</h1>
            <h2 className="mb-5 text-center">{UiStore.user.email}</h2>
            <div className="list-group" style={{ minWidth: "300px" }}>
              {[
                ["/users/", "Users"],
                ["/clinics/", "Clinics"],
                ["/doctors/", "Doctors"],
                ["/patients/", "Patients"],
                ["/treatments/", "Treatments"],
                ["/technicians/", "Technicians"],
              ].map((i) => (
                <Link
                  key={i[0]}
                  to={`${i[0]}`}
                  className="list-group-item list-group-item-action text-center"
                >
                  {i[1]}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
