import React, { useState } from "react";

import { Link, useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import { toast } from "react-toastify";

import * as Api from "../services/Api";
import { UiStore } from "../stores/UiStore";
import { ALLOWED_ADMIN_APP_ROLES } from "../services/constants";

const Login = () => {
  let history = useHistory();
  let location = useLocation();
  const [prevFrom, _] = useState(location.state.from);

  console.log("location state", location, location.state, prevFrom);

  return (
    <div>
      <nav className="zz-navbar">
        <Link className="zz-navbar__logo text-white" to="/">
          ZZ
        </Link>
      </nav>
      <div className="container">
        <div className="row justify-content-center">
          <div>
            <h1 className="my-5 text-center">Login</h1>

            <Formik
              initialValues={{ email: "", password: "" }}
              validateOnChange={false}
              validateOnBlur={false}
              validate={(values) => {
                const errors = {};

                if (!values.email) {
                  errors.email = true;
                }
                if (!values.password) {
                  errors.password = true;
                }

                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                (async function () {
                  try {
                    let res = await Api.login(values.email, values.password);

                    Api.setAccessToken(res.data.accessToken);

                    res = await Api.getUserProfile();

                    if (!ALLOWED_ADMIN_APP_ROLES.includes(res.data.role)) {
                      throw Error("Access denied");
                    }

                    UiStore.user = res.data;
                    const from =
                      prevFrom && prevFrom.pathname + prevFrom.search;
                      console.log('TO', from);
                      
                    history.push(from || "/");
                  } catch (error) {
                    setSubmitting(false);

                    if (error.response && error.response.status === 401) {
                      toast.error("Invalid login/password", {
                        position: "bottom-center",
                      });
                    } else {
                      toast.error("Error: " + error, {
                        position: "bottom-center",
                      });
                    }
                  } finally {
                  }
                })();
                // setTimeout(() => {
                //   alert(JSON.stringify(values, null, 2));
                //   setSubmitting(false);
                // }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md">
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          name="email"
                          className={
                            "form-control" + (errors.email ? " is-invalid" : "")
                          }
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></input>
                        <div className="invalid-feedback">Enter email</div>
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          type="password"
                          name="password"
                          className={
                            "form-control" +
                            (errors.password ? " is-invalid" : "")
                          }
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></input>
                        <div className="invalid-feedback">Enter password</div>
                      </div>

                      <button type="submit" className="btn btn-primary">
                        Login
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
