export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFileExt(fileName) {
  const parts = fileName.split('.');
  if (parts.length < 2) {
    return '';
  }

  return parts.pop();
}
