import Axios from "axios";

// const API_URL = "http://localhost:8777";
// const API_URL = process.env.REACT_APP_BACKEND_URL;
const API_URL = 'https://api.zz.gramotei.net';

let sessionToken = null; // temp token, used by file viewer now

export function setAccessToken(token, isSessionToken) {
  if (isSessionToken) {
    sessionToken = token;
  } else {
    sessionToken = null;
    localStorage.setItem("zz.admin.token", token);
  }
}

export function getAccessToken() {
  return sessionToken || localStorage.getItem("zz.admin.token");
}

function getAuthHeaders() {
  return {
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  };
}

export async function login(email, password) {
  return Axios.post(`${API_URL}/auth/login`, { email, password });
}

export async function getUserProfile() {
  return Axios.get(`${API_URL}/auth/profile`, getAuthHeaders());
}

export async function getUsers() {
  return Axios.get(`${API_URL}/users`, getAuthHeaders());
}

export async function getUserById(userId) {
  return Axios.get(`${API_URL}/users/${userId}`, getAuthHeaders());
}

export async function addUser(data) {
  return Axios.put(`${API_URL}/users/`, data, getAuthHeaders());
}

export async function updateUser(userId, data) {
  return Axios.post(`${API_URL}/users/${userId}`, data, getAuthHeaders());
}

export async function getClinics() {
  return Axios.get(`${API_URL}/clinics`, getAuthHeaders());
}

export async function getClinicById(clinicId) {
  return Axios.get(`${API_URL}/clinics/${clinicId}`, getAuthHeaders());
}

export async function getDoctors(filter) {
  const options = getAuthHeaders();
  options.params = filter;

  return Axios.get(`${API_URL}/doctors`, options);
}

export async function getDoctorById(clinicId) {
  return Axios.get(`${API_URL}/doctors/${clinicId}`, getAuthHeaders());
}

export async function getPatients(filter) {
  const options = getAuthHeaders();
  options.params = filter;

  return Axios.get(`${API_URL}/patients`, options);
}

export async function getProjects(filter) {
  const options = getAuthHeaders();
  options.params = filter;

  return Axios.get(`${API_URL}/projects`, options);
}

export async function getProjectById(projectId) {
  return Axios.get(`${API_URL}/projects/${projectId}`, getAuthHeaders());
}

export async function getProjectVisitById(projectId, visitId) {
  return Axios.get(
    `${API_URL}/v2/proxy/api/Treatment/${projectId}/Appointment/${visitId}`,
    getAuthHeaders()
  );
}

export async function unpackProjectVisitFileForViewing(
  projectId,
  visitId,
  fileId
) {
  return Axios.get(
    `${API_URL}/v2/projects/${projectId}/visits/${visitId}/files/${fileId}/unpackForViewing`,
    getAuthHeaders()
  );
}

export async function getTechnicians(filter) {
  const options = getAuthHeaders();
  options.params = filter;

  return Axios.get(`${API_URL}/technicians`, options);
}

export async function getTechnicianById(technicianId) {
  return Axios.get(`${API_URL}/technicians/${technicianId}`, getAuthHeaders());
}

export async function addTechnician(data) {
  return Axios.put(`${API_URL}/technicians/`, data, getAuthHeaders());
}

export async function updateTechnician(technicianId, data) {
  return Axios.post(
    `${API_URL}/technicians/${technicianId}`,
    data,
    getAuthHeaders()
  );
}

export async function techniciansAssignProject(technicianId, projectId) {
  return Axios.post(
    `${API_URL}/technicians/${technicianId}/assignProject`,
    { projectId },
    getAuthHeaders()
  );
}

export async function techniciansRemoveProject(technicianId, projectId) {
  return Axios.post(
    `${API_URL}/technicians/${technicianId}/removeProject`,
    { projectId },
    getAuthHeaders()
  );
}
