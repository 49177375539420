import {
  BufferGeometry,
  FileLoader,
  Float32BufferAttribute,
  Loader,
  LoaderUtils,
} from "three";

/*

Usage example:

loadOff() {
  const loader = new OFFLoader();
  loader.load(
    url,
    (geometry) => {
      geometry.computeVertexNormals();

      const material = new THREE.MeshPhongMaterial({
        color: 0xff5533,
        specular: 0x111111,
        shininess: 200,
      });
      const mesh = new THREE.Mesh(geometry, material);

      this.scene.add(mesh);
    }
  );
}

*/

var OFFLoader = function (manager) {
  Loader.call(this, manager);
};

OFFLoader.prototype = Object.assign(Object.create(Loader.prototype), {
  constructor: OFFLoader,

  load: function (url, onLoad, onProgress, onError) {
    var scope = this;

    var loader = new FileLoader(this.manager);
    loader.setPath(this.path);
    loader.setResponseType("arraybuffer");
    loader.setRequestHeader(this.requestHeader);
    loader.setWithCredentials(this.withCredentials);

    loader.load(
      url,
      function (text) {
        try {
          onLoad(scope.parse(text));
        } catch (e) {
          if (onError) {
            onError(e);
          } else {
            console.error(e);
          }

          scope.manager.itemError(url);
        }
      },
      onProgress,
      onError
    );
  },

  parse: function (data) {
    function parseASCII(data) {
      if (false === /^OFF/.test(data)) {
        throw Error("Not valid OFF file");
      }
      let patternLine = /^([\s\S]+?)$/gm;

      let geometry = new BufferGeometry();

      let result;

      // 1st line - OFF header
      patternLine.exec(data);

      // 2nd line - COUNT info
      const countLine = patternLine.exec(data);

      const counts = countLine[0].trim().split(" ");
      let countVertex = parseInt(counts[0]);
      let countFace = parseInt(counts[1]);
      let countEdge = parseInt(counts[2]);

      const vertexes = new Float32Array(countVertex * 3);

      for (let i = 0; i < countVertex; i++) {
        result = patternLine.exec(data);
        const parts = result[0].trim().split(/\s+/);

        const idx = i * 3;
        vertexes[idx + 0] = parseFloat(parts[0]);
        vertexes[idx + 1] = parseFloat(parts[1]);
        vertexes[idx + 2] = parseFloat(parts[2]);
      }

      const vertices = new Float32Array(countFace * 3 * 3);

      for (let i = 0; i < countFace; i++) {
        result = patternLine.exec(data);
        const parts = result[0].trim().split(/\s+/);
        const num = parseInt(parts[0]);

        if (num === 3) {
          for (let m = 0; m < num; m++) {
            const vi = parseInt(parts[m + 1]);
            const vertexOfs = vi * 3;

            const verticeOfs = i * 3 * 3;

            vertices[verticeOfs + m * 3 + 0] = vertexes[vertexOfs + 0];
            vertices[verticeOfs + m * 3 + 1] = vertexes[vertexOfs + 1];
            vertices[verticeOfs + m * 3 + 2] = vertexes[vertexOfs + 2];
          }
        } else {
          console.error(parts);
          throw Error("Only faces with 3 vertexes are supported now");
        }
      }

      geometry.setAttribute(
        "position",
        new Float32BufferAttribute(vertices, 3)
      );

      return geometry;
    }

    function ensureString(buffer) {
      if (typeof buffer !== "string") {
        return LoaderUtils.decodeText(new Uint8Array(buffer));
      }

      return buffer;
    }

    // start

    return parseASCII(ensureString(data));
  },
});

export { OFFLoader };
