import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function getLogoUrl(storage) {
  if (storage && storage.storageProvider === "url") {
    return storage.storageUri;
  }

  return "";
}

const Clinics = () => {
  const [loading, setLoading] = useState(false);
  const [clinics, setClinics] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const resp = await Api.getClinics();
        setClinics(resp.data || []);
      } catch (error) {
        toast.error("Error " + error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().catch(() => {});
  }, []);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="row mb-3">
            <div className="col-md d-flex justify-content-between align-items-center">
              <h1>Clinics</h1>
              {/* <Link to="/clinics/add">Add</Link> */}
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Logo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {clinics.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <img
                            src={getLogoUrl(item.picture)}
                            style={{ width: 36, height: 36 }}
                          />
                        </td>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td align="right">
                          <Link
                            className="btn btn-secondary"
                            to={`/doctors/?clinicId=${item._id}`}
                          >
                            Doctors
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Clinics;
