import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { toast } from "react-toastify";

import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { useRouteMatch, useHistory, useLocation, Link } from "react-router-dom";

function useQuery() {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
}

const EditUser = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const query = useQuery();

  const userId = match.params.userId;

  useEffect(() => {
    (async () => {
      setLoading(true);
      let u = null;
      if (userId) {
        u = (await Api.getUserById(userId)).data;
      } else {
        u = {
          accountType: query.get("accountType") || "user",
          entityId: query.get("entityId"),
        };
      }

      if (!u.role) u.role = "user";

      if (u.accountType !== "user") {
        switch (u.accountType) {
          case "doctor":
            u.entity = (await Api.getDoctorById(u.entityId)).data;
            u.entityText = [u.entity.firstName, u.entity.lastName].join(" ");
            u.entityLink = `/doctors/?doctorId=${u.entityId}`;
            break;
          case "technician":
            u.entity = (await Api.getTechnicianById(u.entityId)).data;
            u.entityText = [u.entity.firstName, u.entity.lastName].join(", ");
            u.entityLink = `/technicians/${u.entityId}`;
            break;
          default:
            break;
        }
      }

      setUser(u);

      setLoading(false);
    })();
  }, [query, userId]);

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="row mb-3">
            <div className="col-md">
              <h1>User - Edit</h1>
              {user.entityId ? (
                <h2>
                  For →{" "}
                  <Link to={user.entityLink}>
                    {user.accountType}: {user.entityText}
                  </Link>
                </h2>
              ) : null}
            </div>
          </div>
          <Formik
            initialValues={user}
            validate={(values) => {
              const errors = {};

              if (!values.email || !values.email.trim()) {
                errors.email = "Email is required";
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              (async function () {
                try {
                  const { entity, entityLink, entityText, ...rest } = values;
                  if (userId) {
                    await Api.updateUser(userId, rest);
                    toast.success("Saved");
                  } else {
                    // eslint-disable-next-line no-use-before-define
                    const u = (await Api.addUser(rest)).data;
                    history.push(`/users/${u._id}`);
                  }
                } catch (error) {
                  console.log("error", error);
                  toast.error("Error " + error);
                } finally {
                  setSubmitting(false);
                }
              })();
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   setSubmitting(false);
              // }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="text"
                        className={
                          "form-control" + (errors.email ? " is-invalid" : "")
                        }
                        name="email"
                        value={values.email || ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></input>
                      <div className="invalid-feedback">{errors.email}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label>New password</label>
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (errors.password ? " is-invalid" : "")
                        }
                        name="password"
                        value={values.password || ""}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></input>
                      <div className="invalid-feedback">{errors.password}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label>Role</label>
                      {values.accountType === "user" ? (
                        <select
                          className="form-control"
                          name="role"
                          value={values.role || ""}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        >
                          <option value={"admin"}>Admin</option>
                          <option value={"user"}>User</option>
                        </select>
                      ) : (
                        <input
                          type="text"
                          readOnly
                          className="form-control-plaintext"
                          value={"N/A"}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label>Account Type</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control-plaintext"
                        value={values.accountType}
                      />
                    </div>
                  </div>
                </div>
                {values.accountType !== "user" ? (
                  <div className="row">
                    <div className="col-md">
                      <div className="form-group">
                        <label>Entity ID</label>
                        <input
                          type="text"
                          readOnly
                          className="form-control-plaintext"
                          value={values.entityId || ""}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-md">
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default EditUser;
