import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const resp = await Api.getUsers();
        const items = resp.data;

        for (let i = 0; i < items.length; i++) {
          const u = items[i];

          if (u.accountType !== "user") {
            switch (u.accountType) {
              case "doctor":
                u.entity = (await Api.getDoctorById(u.entityId)).data;
                u.entityText = [u.entity.firstName, u.entity.lastName].join(
                  " "
                );
                u.entityLink = `/doctors/?doctorId=${u.entityId}`;
                break;
              case "technician":
                u.entity = (await Api.getTechnicianById(u.entityId)).data;
                u.entityText = [u.entity.firstName, u.entity.lastName].join(
                  ", "
                );
                u.entityLink = `/technicians/${u.entityId}`;
                break;
              default:
                break;
            }
          }
        }

        setUsers(items || []);
      } catch (error) {
        toast.error("Error " + error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().catch(() => {});
  }, []);

  // const getEntityLink = (user) => {
  //   if (!user ||!user.entityId) {
  //     return "";
  //   }

  //   switch (user.accountType) {
  //     case 'doctor': return (<Link to="/doc/add">Додати</Link>)
  //   }

  //   return ''
  // }

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <>
          <div className="row mb-3">
            <div className="col-md d-flex justify-content-between align-items-center">
              <h1>Users</h1>
              <Link to="/users/add">Add</Link>
            </div>
          </div>

          <div className="row">
            <div className="col-md">
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Account Type</th>
                    <th>Connected Entity ID</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((u, i) => {
                    return (
                      <tr key={i}>
                        <td>{u.email}</td>
                        <td>{u.role}</td>
                        <td>{u.accountType}</td>
                        <td>
                          {u.entityId ? (
                            <Link to={u.entityLink}>{u.entityText}</Link>
                          ) : null}
                        </td>
                        <td align="right">
                          <Link
                            className="btn btn-secondary"
                            to={`/users/${u.id}`}
                          >
                            Details
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Users;
