import React, { useEffect, useMemo, useState } from "react";
import { Loading } from "./Loading";
import * as Api from "../services/Api";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ThreeJsViewer from "./ThreeJsViewer";

function useQuery() {
  const search = useLocation().search;
  return useMemo(() => new URLSearchParams(search), [search]);
}

const FileViewer = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [modelLoaded, setModelLoaded] = useState(false);

  let query = useQuery();
  const previewGeneration = query.get("previewGeneration") === "true";

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);

        const token = query.get("token");
        const projectId = query.get("projectId");
        const visitId = query.get("visitId");
        const fileId = query.get("fileId");

        Api.setAccessToken(token, true);
        const resp = await Api.getProjectVisitById(projectId, visitId);
        const d = { visit: resp.data };
        d.file = d.visit.files.find((x) => x.id === fileId);

        for (let i = 0; i < 300; i++) {
          const r = await Api.unpackProjectVisitFileForViewing(
            projectId, visitId, fileId
          );
          if (r.data.status === "done") {
            d.unpackResult = r.data;
            break;
          }
        }

        if (!d.unpackResult) {
          alert("Timed out while waiting for unpacking");
        }

        setData(d);
        console.log('unpacked', d.unpackResult);
        
      } catch (error) {
        console.log('Error', );
        
        toast.error("Error " + error);
      } finally {
        setLoading(false);
      }
    }

    fetchData().catch(() => {});
  }, [query]);

  const onModelLoaded = () => {
    setTimeout(() => setModelLoaded(true), 500)
    
    console.log("model loaded");
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && data?.unpackResult && (
        <>
          {!previewGeneration && (
            <nav className="zz-navbar">
              <Link className="zz-navbar__logo text-white" to="/">
                ZZ
              </Link>
              <div className="zz-navbar__items">File Viewer</div>
            </nav>
          )}

          <div className="container">
            <div className="row">
              <div className="col-sm">
                <p className="text-center mt-1 mb-2">
                  {data?.file?.name} ( {data?.file?.extension} )
                </p>
              </div>
            </div>
          </div>
          <ThreeJsViewer
            fileUrl={data.unpackResult.fileUrl}
            fileType={data.unpackResult.fileType}
            baseUrl={data.unpackResult.baseUrl}
            files={data.unpackResult.files}
            // previewGeneration={previewGeneration}
            defaultFov={previewGeneration ? 20 : null}
            onModelLoaded={onModelLoaded}
          />
          {/* <ThreeJsViewer fileUrl="http://localhost:8777/static/testfiles/2020-10-26_00016-098-lowerjaw.stl" /> */}
          {/* <ThreeJsViewer fileUrl="http://localhost:8777/static/testfiles/2020-10-02_00019-079-UpperJaw.ply" /> */}
          {/* <ThreeJsViewer fileUrl="http://localhost:8777/static/testfiles/2017-04-10_100971-010-Face-MouthClosed-facescan.obj" /> */}
          {/* <ThreeJsViewer fileUrl="http://localhost:8777/static/testfiles/2017-04-10_100971-010-46-situ.off" /> */}
          {modelLoaded && <div className="fv-model-loaded" />}
        </>
      )}
    </>
  );
};

export default FileViewer;
